import React, { Component } from "react"
import styles from "../../css/items.module.css"
import Region from "./Region"
import Title from "../Title"

export default class RegionList extends Component {
  state = {
    regions: [],
    sortedRegions: [],
  }

  componentDidMount() {
    this.setState({
      regions: this.props.regions.edges,
      sortedRegions: this.props.regions.edges,
    })
  }

  render() {
    return (
      <section className={styles.items}>
        <Title title="le" subtitle="Cher" />
        {this.state.sortedRegions.map(({ node }) => {
          return <Region key={node.id} region={node} />
        })}
      </section>
    )
  }
}
