import React from "react"
import RegionList from "./RegionList"
import { useStaticQuery, graphql } from "gatsby"

const getRegions = graphql`
  query {
    regions: allContentfulRegion(sort: { fields: position, order: ASC }) {
      edges {
        node {
          id: contentful_id
          titre
          location {
            lat
            lon
          }
          image {
            title
            fluid {
              ...GatsbyContentfulFluid
            }
          }
          description {
            description
          }
          distance
        }
      }
    }
  }
`

const Regions = () => {
  const { regions } = useStaticQuery(getRegions)
  return <RegionList regions={regions} />
}

export default Regions
