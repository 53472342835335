import React, { Component } from "react"
import icon from "../images/icon-leaflet.svg"

// pour window qui n'est pas disponible au build
let Map,
  L,
  TileLayer,
  Marker,
  Tooltip,
  ZoomControl = null

export default class MapLeaflet extends Component {
  constructor(props) {
    super(props)
    this.lat = this.props.lat
    this.lon = this.props.lon
    this.tooltip = this.props.tooltip
    this.className = this.props.className
    this.icon = null
    L = require("leaflet")
    Map = require("react-leaflet").Map
    TileLayer = require("react-leaflet").TileLayer
    Marker = require("react-leaflet").Marker
    Tooltip = require("react-leaflet").Tooltip
    ZoomControl = require("react-leaflet").ZoomControl
  }

  render() {
    if (Map !== null) {
      this.icon = new L.Icon({
        iconUrl: icon,
        iconRetinaUrl: icon,
        iconSize: [64, 64],
        iconAnchor: [32, 64],
      })
      return (
        <Map
          className={this.className}
          center={[this.lat, this.lon]}
          zoom={13}
          maxZoom={17}
          attributionControl={true}
          zoomControl={false}
          doubleClickZoom={true}
          scrollWheelZoom={false}
          dragging={true}
          animate={true}
          easeLinearity={0.35}
        >
          <TileLayer
            attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <ZoomControl
            position="topright"
            zoomInTitle="Zoom +"
            zoomOutTitle="Zoom -"
          />
          <Marker position={[this.lat, this.lon]} icon={this.icon}>
            <Tooltip direction="left" offset={[-20, -32]}>
              {this.tooltip}
            </Tooltip>
          </Marker>
        </Map>
      )
    }
    return null
  }
}
