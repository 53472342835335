import React from "react"
import styles from "../../css/region.module.css"
import Image from "gatsby-image"
import MapLeaflet from "../MapLeaflet"
import PropTypes from "prop-types"
import { FaCarAlt } from "react-icons/fa"

const Region = ({ region }) => {
  const {
    titre,
    description: { description },
    location,
    distance,
  } = region
  return (
    <div className={styles.region}>
      <div className={styles.regionFlex}>
        <article
          className={
            region.image
              ? styles.regionInfo
              : `${styles.regionInfo} ${styles.regionNoImg}`
          }
        >
          <h4>{titre}</h4>
          <p>{description}</p>
          <p>
            {distance > 0 ? (
              <span>
                <FaCarAlt className={styles.icon} />{" "}
                {distance < 1 ? `${distance * 1000} m` : `${distance} km`}
              </span>
            ) : null}
          </p>
        </article>
        {region.image ? (
          <article className={styles.regionImg}>
            <div className={styles.imgContainer}>
              <Image fluid={region.image.fluid} alt={region.image.title} />
            </div>
          </article>
        ) : null}
      </div>
      {location ? (
        <MapLeaflet
          lat={location.lat}
          lon={location.lon}
          tooltip="Moulin de Gâteau"
          className={styles.regionMap}
        />
      ) : null}
    </div>
  )
}

Region.propTypes = {
  region: PropTypes.shape({
    titre: PropTypes.string.isRequired,
    description: PropTypes.object.isRequired,
  }),
}

export default Region
